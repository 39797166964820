/* Customize your main colors in :root variables */
:root {
  --main-background-color: #FCC730; /*#343A50*/
  --card-background-color: #FCC730; /*#51576D, 294654, 2c4e5c, 8cb3c4*/
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#FFFFFF;
  --title-text-color:#FCC730;
}

body{
  background-color:var(--main-background-color);
  background: url("img/backgroundwp.png");
  background-size: cover;

  font-family: 'Roboto', bold;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



